import React from 'react'

export default function SafeAndConfidential() {
  return (
    <div className="safe-and-confidential">
      <h2 className="safe-and-confidential__header">Safe & confidential</h2>
      <p>
        We’ve taken the necessary steps to keep your private information - private. Aidia is HIPAA compliant and meets
        other regulatory standards, while operating on state-of-the-art, secure servers.
      </p>
    </div>
  )
}
