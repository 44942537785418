import React, { useState } from 'react'
import ProductEligibilityConfirmation from '../components/ProductEligibilityConfirmation'
import ProductEligibilityForm from '../components/ProductEligibilityForm'

export default function howToGetAidiaPage() {
  const [isSubitted, setIsSubmitted] = useState(false)

  if (isSubitted) {
    return (
      <main>
        <ProductEligibilityConfirmation />
      </main>
    )
  }

  return (
    <main>
      <ProductEligibilityForm setIsSubmitted={setIsSubmitted} />
    </main>
  )
}
