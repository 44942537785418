import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link } from 'gatsby'
import * as Api from '../utils/api'
import Hero from './Hero'
import Input from './Input'
import Textarea from './Textarea'
import Checkbox from './Checkbox'
import RadioList from './RadioList'
import TelephoneInput from './TelephoneInput'
import Button from './Button'
import Popup from './Popup'
import { FaArrowRight } from 'react-icons/fa'
import {
  hasError,
  interestOptions,
  eligibilityResponsePreferenceOptions,
  timezoneOptions,
  timeOfDayOptions,
} from '../utils/form'

export default function ProductEligibilityForm({ setIsSubmitted }) {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    inquiry_myself_or_loved_one: '',
    time_zone_preference: '',
    time_of_day_response_preference: '',
    preferred_contact_response: '',
    marketing_contact_opt_in: false,
    consumer_information_sharing_consent: false,
    prescribed_medication: '', // ppi can't go to hubspot
    condition: '', // ppi can't go to hubspot
    mapleSyrup: '',
  }
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      setError(null)

      // https://www.thryv.com/blog/honeypot-technique/
      // can implement on backend
      if (values.mapleSyrup) {
        setError({ message: 'Failed to submit.', code: 409 })
        return
      }
      delete values.mapleSyrup

      await Api.postProductEligibility(values)
      setLoading(false)
      setError(null)
      setIsSubmitted(true)

      window.scrollTo(0, 0)
    } catch (error) {
      setLoading(false)
      setError({ message: error.error, code: error.code })
    }
  }
  const validate = (values) => {
    const errors = {}

    if (!values.firstname) {
      errors.firstname = 'Please enter your first name.'
    }

    if (!values.lastname) {
      errors.lastname = 'Please enter your last name.'
    }

    if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "That email address doesn't look quite right."
    }

    if (!values.phone || values.phone.length !== 14) {
      errors.phone = 'Please confirm that your phone number is correct.'
    }

    if (!values.inquiry_myself_or_loved_one) {
      errors.inquiry_myself_or_loved_one = 'Please make a selection.'
    }

    if (!values.preferred_contact_response) {
      errors.preferred_contact_response = 'Please make a selection.'
    }

    if (!values.condition) {
      errors.condition = 'Please enter a condition.'
    }

    if (!values.prescribed_medication) {
      errors.prescribed_medication = 'Please enter prescribed medication.'
    }

    if (!values.marketing_contact_opt_in) {
      errors.marketing_contact_opt_in = 'Please check the box above to proceed.'
    }

    if (!values.consumer_information_sharing_consent) {
      errors.consumer_information_sharing_consent = 'Please check the box above to proceed.'
    }

    return errors
  }

  return (
    <>
      <Hero
        heading="Staying on schedule starts here"
        text="Aidia focuses on your medication schedule, so you can focus on what matters most. "
        image="clock-devices"
      />
      <div className="page-section">
        <div className="form-container">
          <h2 className="header">Aidia is not available for direct purchase.</h2>
          <h3 className="subheader">
            The Aidia System™ is provided by participating healthcare partners—at no cost to you. To find out if your
            medication is covered, please complete the form below.
          </h3>
          <span className="fields-required-disclaimer">All fields are required, unless otherwise noted.</span>
          {error && (
            <span>
              {error.message} {error.code && `Error code: ${error.code}`}
            </span>
          )}
          <Formik
            initialValues={initialValues}
            validate={(values) => validate(values)}
            onSubmit={async (values) => await handleFormSubmit(values)}
          >
            {({ values, isSubmitting, isValid, errors, submitCount, setFieldValue }) => {
              return (
                <Form>
                  <fieldset>
                    <Input name="firstname" label="First Name" hasError={hasError('firstname', submitCount, errors)} />
                    <Input name="lastname" label="Last Name" hasError={hasError('lastname', submitCount, errors)} />
                    <Input
                      name="email"
                      label="Email address"
                      type="email"
                      hasError={hasError('email', submitCount, errors)}
                    />
                    <div className="phone">
                      <TelephoneInput
                        name="phone"
                        label="Phone Number (number to contact)"
                        hasError={hasError('phone', submitCount, errors)}
                        previousValue={values.phone}
                        setFieldValue={setFieldValue}
                      />
                      <Popup text="An Aidia Specialist may need to call to complete your application or assist in finding an available program." />
                    </div>
                    <RadioList
                      name="inquiry_myself_or_loved_one"
                      label="I am interested in Aidia for:"
                      options={interestOptions}
                      hasError={hasError('inquiry_myself_or_loved_one', submitCount, errors)}
                      className="inquiry_myself_or_loved_one"
                      horizontal
                    />
                    <Input
                      name="condition"
                      label="Condition/Conditions being treated:"
                      hasError={hasError('condition', submitCount, errors)}
                    />
                    <Textarea
                      name="prescribed_medication"
                      label="My current prescribed medications:"
                      subLabel="Please list all medications prescribed for the above condition(s)"
                      hasError={hasError('prescribed_medication', submitCount, errors)}
                    />
                    <RadioList
                      name="preferred_contact_response"
                      label="Preferred response"
                      options={eligibilityResponsePreferenceOptions}
                      hasError={hasError('preferred_contact_response', submitCount, errors)}
                      className="preference"
                      horizontal
                    />
                    {values.preferred_contact_response && (
                      <>
                        <RadioList
                          name="time_zone_preference"
                          label="US time zone:"
                          options={timezoneOptions}
                          hasError={hasError('time_zone_preference', submitCount, errors)}
                          horizontal
                        />
                        <RadioList
                          name="time_of_day_response_preference"
                          label="Time of day:"
                          options={timeOfDayOptions}
                          hasError={hasError('time_of_day_response_preference', submitCount, errors)}
                          horizontal
                        />
                      </>
                    )}
                    <Checkbox
                      name="marketing_contact_opt_in"
                      hasError={hasError('marketing_contact_opt_in', submitCount, errors)}
                      label={
                        <span>
                          By checking this box and clicking “Submit” you agree to be contacted by AdhereTech agents by
                          direct mail, email, telephone, and text message (including autodialed and prerecorded calls
                          and messages—msg & data rates may apply) for marketing purposes and other information related
                          to adherence and other support services from AdhereTech. Your consent is not a condition of
                          any purchase. You may choose to no longer receive further communications from AdhereTech and
                          its agents by emailing{' '}
                          <a href="mailto:unsubscribe@adheretech.com?subject=Unsubscribe from AdhereTech communications">
                            unsubscribe@adheretech.com
                          </a>{' '}
                          or following the unsubscribe instructions on the communication. For more information, view
                          AdhereTech&apos;s <Link to="/privacy-policy">Privacy Policy</Link>.
                        </span>
                      }
                    />
                    <Checkbox
                      name="consumer_information_sharing_consent"
                      hasError={hasError('consumer_information_sharing_consent', submitCount, errors)}
                      label={
                        <span>
                          By checking this box and clicking “Submit” you authorize Adheretech to share your personal
                          information with our third party partners such as specialty pharmacies, pharmaceutical
                          manufacturers, patient service providers and related health care entities in order to
                          determine your eligibility for adherence and other related healthcare services provided via
                          AdhereTech to you. You have the right to revoke this authorization at any time by notifying{' '}
                          <a href="mailto:support@adheretech.com?subject=Revoke AdhereTech sharing permissions">
                            support@adheretech.com
                          </a>{' '}
                          but it will not be effective to the extent that any third party has already acted in reliance
                          on this authorization.
                        </span>
                      }
                    />
                    <Field type="text" name="mapleSyrup" />
                  </fieldset>
                  <Button color="blue" type="submit" disabled={isSubmitting || (submitCount > 0 && !isValid)}>
                    <span>Submit</span>
                    <FaArrowRight className="arrow-right" />
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}
