import React from 'react'
import FormConfirmation from '../components/FormConfirmation'
import SafeAndConfidential from '../components/SafeAndConfidential'
import ActionBanner from '../components/ActionBanner'

export default function ProductEligibilityConfirmation() {
  return (
    <div className="form-confirmation__container">
      <div className="page-section">
        <FormConfirmation>
          <p>We have received your information and an Aidia Specialist will contact you via the number you provided.</p>
          <p>
            If Aidia is not currently available for your medication, don’t worry. We are continuously growing our
            healthcare partnerships. Your information will be added to our database, and we will reach out once Aidia
            becomes available.
          </p>
        </FormConfirmation>
      </div>
      <div className="page-section">
        <SafeAndConfidential />
      </div>
      <ActionBanner
        header={null}
        content="Get better acquainted with Aidia by checking out these frequently asked questions."
        linkPath="/faqs"
        linkText="View FAQs"
      />
    </div>
  )
}
