import React, { useState } from 'react'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'

export default function Popup({ text }) {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div className="popup">
      <FaInfoCircle className="info-circle" onClick={() => setIsVisible(!isVisible)} />
      {isVisible && (
        <div className="popup__text">
          <span>{text}</span>
          <FaTimes className="times" size={22} onClick={() => setIsVisible(false)} />
        </div>
      )}
    </div>
  )
}
